<template>
    <div class="footer">
        <div class="footer__section">
            <div class="footer__section-item" data-t="play_pass">Play Pass</div>
            <div class="footer__section-item" data-t="play_points">Play Points</div>
            <div class="footer__section-item" data-t="gift_cards">Kartu voucher</div>
            <div class="footer__section-item" data-t="redeem">Tukarkan</div>
            <div class="footer__section-item" data-t="refund_policy">Kebijakan pengembalian dana</div>
        </div>
        <div class="footer__section">
            <div class="footer__section-title" data-t="children_and_family">Anak-anak & keluarga</div>
            <div class="footer__section-item" data-t="family_guide">Panduan Orang Tua</div>
            <div class="footer__section-item" data-t="family_sharing">Berbagi dengan keluarga</div>
        </div>
        <div class="footer__bottom flex items-center">
            <div class="mr-22" data-t="service_terms">Persyaratan Layanan</div>
            <div class="mr-22" data-t="privacy">Privasi</div>
        </div>
        <div class="footer__bottom flex items-center">
            <div class="mr-22" data-t="developers">Developer</div>
            <div class="mr-22" data-t="language">Indonesia</div>
        </div>
    </div>
</template>

<script>
    
</script>