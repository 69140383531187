<template>
    <div class="img-scroll">
        <div class="img-scroll__list" data-value="pic_list">
            <div class="img-scroll__view">
                <img loading="lazy" alt="" src="/assets/images/screenshots/screenshot-1.png">
            </div>
            <div class="img-scroll__view">
                <img loading="lazy" alt="" src="/assets/images/screenshots/screenshot-2.png">
            </div>
            <div class="img-scroll__view">
                <img loading="lazy" alt="" src="/assets/images/screenshots/screenshot-3.png">
            </div>
        </div>
    </div>
</template>

<script>
    
</script>