<template>
    <div v-if="isSetupFirst">
        <FixedHeader />
        <div class="container" data-type="INSTALL" id="container" @click="installPWA">
            <MainHeader
                :app-brand="appConfig.appBrand"
                :app-server="appConfig.appServer"
                :app-rating="appConfig.appRating"
                :app-review-count="appConfig.appReviewCount"
                :app-download-count="appConfig.appDownloadCount"
            />
            <QrBox
                :app-brand="appConfig.appBrand"
                :app-qr-link="appConfig.appQrLink"
            />
            <ScreenShot />
            <DescriptionBox
                :app-brand="appConfig.appBrand"
                :app-keywords="appConfig.appKeywords"
                :app-last-update="appConfig.appLastUpdate"
            />
            <CommentsBox
                :app-rating="appConfig.appRating"
                :app-review-count="appConfig.appReviewCount"
                :app-reviews="appConfig.appReviews"
            />
            <MainFooter />
        </div>
        <FixedMenu />
        <ModalBox
            :app-box-loaded="appBoxLoaded"
            :app-brand="appConfig.appBrand"
            :app-server="appConfig.appServer"
            @handleModal="handleModal"
        />
    </div>
    <div v-else-if="isSetupDone">
        <div class="stand-alone-wrapper">
            <div class="header">
                <div class="header__icon">
                    <img class="header__icon--img" data-value="app_icon" loading="lazy" src="/assets/images/icons/android-chrome-512x512.png">
                </div>
                <div class="header__info-title">
                    <span data-value="app_name">{{ appConfig.appBrand }}</span>
                </div>
                <div v-show="showLoading" class="loader"></div>
                <div v-show="showBtnPlay" class="play-btn" @click="openPWAWindow">
                    <span>Main Sekarang</span>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="stand-alone-wrapper">
            <div class="header">
                <div class="header__icon">
                    <img class="header__icon--img" data-value="app_icon" loading="lazy" src="/assets/images/icons/android-chrome-512x512.png">
                </div>
                <div class="header__info-title">
                    <span data-value="app_name">{{ appConfig.appBrand }}</span>
                </div>
                <div class="loader"></div>
            </div>
        </div>
    </div>
</template>

<script>
import FixedHeader from '@/components/FixedHeader.vue'
import FixedMenu from '@/components/FixedMenu.vue'
import MainHeader from '@/components/MainHeader.vue'
import MainFooter from '@/components/MainFooter.vue'
import DescriptionBox from '@/components/DescriptionBox.vue'
import CommentsBox from '@/components/CommentsBox.vue'
import QrBox from '@/components/QrBox.vue'
import ModalBox from '@/components/ModalBox.vue'
import ScreenShot from './components/ScreenShot.vue'
import axios from 'axios'

export default {
    data() {
        return {
            appSetupURL: window.location.origin + '?start_app=true',
            appBoxLoaded: false,
            appConfig: {},
            isSetupFirst: false,
            isSetupDone: false,
            tabFocus: false,
            shown: false,
            showLoading: true,
            showBtnPlay: false,
        }
    },
    async beforeMount() {
        await axios.get('/webConfig.json').then(response => {
            this.appConfig = response.data;
            document.title = response.data.appBrand;

            const loadWeb = new URL(location.href).searchParams.get('start_app');

            if (loadWeb === null) {
                this.isSetupFirst = true;
            } else {
                // window.location.replace(response.data.appWebsite);

                if (window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches || document.referrer.includes('android-app://')) {
                    window.location.replace(response.data.appWebsite);
                } else {
                    this.isSetupDone = true;
                }
            }
        })

        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault()
            this.installEvent = e
            this.shown = true
        })
    },
    mounted() {
        this.appBoxLoading();
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
        this.detectFocusOut();
        setTimeout(() => this.showLoading = false, 17500);
        setTimeout(() => this.showBtnPlay = true, 17500);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    watch: {
        tabFocus(value) {
            if (value === false) {
                let modalBox = document.querySelector("#rb-back-layer");

                modalBox.classList.remove('layer--hidden');
                modalBox.classList.add('layer--visible');
            }
        }
    },
    methods: {
        appBoxLoading() {
            setTimeout(() => {
                this.appBoxLoaded = true;
            }, 500);
        },
        handleScroll(event) {
            let header = document.querySelector(".topbar");

            if (window.scrollY > 100) {
                header.classList.add('active');
            } else {
                header.classList.remove('active');
            }
        },
        handleModal() {
            let modalBox = document.querySelector("#rb-back-layer");

            modalBox.classList.remove('layer--visible');
            modalBox.classList.add('layer--hidden');
        },
        detectFocusOut() {
            const onWindowFocusChange = () => {
                if(document.hidden) {
                    this.tabFocus = !this.tabFocus;
                } else {
                    this.tabFocus = true;
                }
            };

            document.addEventListener('visibilitychange', onWindowFocusChange);
        },
        dismissPrompt() {
            this.shown = false
        },
        installPWA() {
            this.installEvent.prompt()
            this.installEvent.userChoice.then((choice) => {
                this.dismissPrompt()
                if (choice.outcome === 'accepted') {
                    window.location.replace(window.location.href + '?start_app=true');
                    // window.location.replace(this.appConfig.appWebsite);
                } else {
                    // Do something additional if the user declined
                }
            })
        },
        openPWAWindow() {
            window.open(this.appSetupURL);
        },
    },
    components: {
        FixedHeader,
        FixedMenu,
        MainHeader,
        MainFooter,
        DescriptionBox,
        CommentsBox,
        QrBox,
        ModalBox,
        ScreenShot,
    },
}
</script>