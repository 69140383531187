<template>
    <div class="rb-menus" id="rb-menus">
        <div class="menus__placeholder"></div>
        <div class="menus__body" id="menus">
            <div class="menus__item active">
                <img loading="lazy" src="/assets/images/menus/menu-game.png">
                <div class="title">Game</div>
            </div>
            <div class="menus__item ">
                <img loading="lazy" src="/assets/images/menus/menu-app.png">
                <div class="title">aplikasi</div>
            </div>
            <div class="menus__item ">
                <img loading="lazy" src="/assets/images/menus/menu-film.png">
                <div class="title">Film &amp; TV</div>
            </div>
            <div class="menus__item ">
                <img loading="lazy" src="/assets/images/menus/menu-book.png">
                <div class="title">Buku</div>
            </div>
            <div class="menus__item ">
                <img loading="lazy" src="/assets/images/menus/menu-star.png">
                <div class="title">Anak-anak</div>
            </div>
        </div>
    </div>
</template>

<script>

</script>